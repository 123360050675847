import { TAdvCommonProperties } from "@components/other/common-properties";
import AdvIcon from "@components/other/icon/icon";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import { Button, ButtonProps, FluentProvider } from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import { TAdvValueBindingParams } from "@hooks/dynamic/useAdvValueBinder";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { useT } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { IAdvIcon } from "@themes/icons_base";
import { mergeObjects } from "@utils/styling";
import React, { useMemo } from "react";
import { EAdvButtonType } from "../button/button-pure";

export type TAdvButtonProps = Pick<
    ButtonProps,
    | "children"
    | "disabled"
    | "style"
    | "icon"
    | "appearance"
    | "title"
    | "onClick"
    | "onContextMenu"
    | "tabIndex"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        /** Alternative zu iconProps.iconName */
        iconName?: string;
        iconProps?: IAdvIcon;
        /**
         * Wenn angegeben wird bei einem Klick auf den Button diese Web-Action ausgeführt.
         * @important Ein bestehendes onClick-Event wird dabei überschrieben.
         */
        webActionParams?: TAdvWebActionParams; // the name and parts of the type structure of this field are used in the server code, in case of rename, also do it in the server code
        // force own text, instead of the one provided by the webaction
        forceOwnText?: boolean;
        // force allow the onclick to be executed even if a webaction is set
        // in this case the web action is overwritten
        forceAllowOnClick?: boolean;

        // a context specializing the translation used
        /** Allgemeines Design des Buttons */
        buttonType?: EAdvButtonType;

        primary?: boolean;
        primaryBindingParams?: TAdvValueBindingParams;

        // they key that is sent as identifier, when a webaction triggers
        keyRef?: string;

        ignoreTranslation?: boolean;

        /**
         * Ein hotkey, der die hinter diesem Button hinterlegte WebAction ausführt
         */
        hotkey?: string;

        /**
         * Setzt automatisch flex properties, damit der Button wächst
         */
        flexGrow?: boolean;
        flexGrowBindingParams?: TAdvValueBindingParams;

        disabledBindingParams?: TAdvValueBindingParams;
        // TODO: Gedanken machen, wie man das besser lösen kann. Konkret: Icon, Big Icon, Icon / Big Icon + Text, Transpaent,...

        simplified?: boolean;
        simplifiedBindingParams?: TAdvValueBindingParams;

        //onRenderText?: IRenderFunction<TAdvButtonProps>;
    };

export const AdvButtonPureNew = React.forwardRef(
    (
        {
            children,
            onClick,
            onContextMenu,
            ignoreTranslation,
            title,
            advhide,
            designerProps,
            designerData,
            buttonType,
            iconName,
            icon,
            iconProps,
            primary,
            style,
            simplified,
            flexGrow,
            ...props
        }: TAdvButtonProps,
        ref: React.ForwardedRef<any>,
    ) => {
        useAdvComponent(AdvButtonPureNew, props);

        const theme = useAdvTheme();
        const v9Theme = useMemo(() => {
            return createV9Theme(theme);
        }, [theme]);

        const { t, hasErr } = useT(typeof children == "string" ? children : "", ignoreTranslation);
        const { t: titleT } = useT(title, ignoreTranslation);

        // Styles
        const [styles, textStyles, iconStyles] = useMemo(() => {
            let textStyles = undefined;
            let iconStyles = undefined;
            let styles = mergeObjects(
                style ?? {},
                flexGrow === true ? { flexGrow: 1, width: "100%" } : {},
            );
            if (hasErr) textStyles = { ...theme.custom.textNotTranslated };

            if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
                styles = mergeObjects(styles, { ...getSelectedComponentStyle(theme, true) });
            if (designerData?.renderAsDesigner ?? false)
                styles = mergeObjects(styles, {
                    ...(getDesignerModeComponentStyle(theme, "button") as any),
                });

            styles = mergeObjects(styles, {
                fontWeight: 400,
                border: "1px solid " + theme.palette.neutralQuaternaryAlt,
            });
            if (iconProps?.color === undefined)
                iconStyles = {
                    color: theme.palette.themePrimary,
                };

            return [Object.keys(styles).length == 0 ? undefined : styles, textStyles, iconStyles];
        }, [
            designerData?.isSelected,
            designerData?.renderAsDesigner,
            flexGrow,
            hasErr,
            iconProps?.color,
            simplified,
            style,
            theme,
        ]);

        /*const myOnRenderText: IRenderFunction<IButtonProps> = useAdvCallback(
            (props?: IButtonProps, defaultRender?: IRenderFunction<IButtonProps>) => {
                if (onRenderText !== undefined) return onRenderText(orgProps);
                if (defaultRender !== undefined) return defaultRender(props);
                return null;
            },
            [onRenderText, orgProps],
        );*/

        if (advhide === true && designerProps === undefined) return <></>;
        return (
            <FluentProvider theme={v9Theme} style={{ backgroundColor: "transparent" }}>
                <Button
                    {...props}
                    onClick={
                        onClick != undefined
                            ? (ev: any) => {
                                  onClick(ev as any);
                              }
                            : undefined
                    }
                    onContextMenu={
                        onContextMenu != undefined
                            ? (ev: any) => {
                                  onContextMenu(ev as any);
                              }
                            : undefined
                    }
                    title={titleT}
                    appearance={
                        buttonType == EAdvButtonType.Primary || primary === true
                            ? "primary"
                            : buttonType == EAdvButtonType.Action
                            ? "outline"
                            : "secondary"
                    }
                    icon={
                        icon != undefined ? (
                            icon
                        ) : iconName != undefined ? (
                            <AdvIcon
                                iconName={iconName}
                                styles={{ root: { ...iconStyles, overflowY: "none" } }}
                            ></AdvIcon>
                        ) : iconProps != undefined ? (
                            <AdvIcon
                                iconName={iconProps.iconName}
                                styles={{ root: { ...iconStyles, overflowY: "none" } }}
                            ></AdvIcon>
                        ) : undefined
                    }
                    style={styles}
                    ref={ref}
                >
                    {typeof children == "string" ? t : children}
                </Button>
            </FluentProvider>
        );
    },
);
AdvButtonPureNew.displayName = "AdvButtonPureNew";

export default AdvButtonPureNew;
